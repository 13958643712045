import transparentize from "polished/lib/color/transparentize";
import React from "react";
import styled from "styled-components";

const StyledNavigationBar = styled.nav`
  background: ${(props) => props.theme.palette.white};
  box-shadow:
    0 -2px 4px 0 ${(props) => transparentize(0.9, props.theme.palette.black)},
    0 -3px 6px 0 ${(props) => transparentize(0.9, props.theme.palette.black)};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export const BottomNavigation: React.FC<{ children: React.ReactNode }> = ({
  children,
  ...props
}) => <StyledNavigationBar {...props}>{children}</StyledNavigationBar>;

export default BottomNavigation;
