import transparentize from "polished/lib/color/transparentize";
import styled from "styled-components";

import Spacings from "../Spacings";

export const VERTICAL_FLAG_WIDTH = "80px";

const VerticalFlag = styled(Spacings.Inset)`
  width: ${VERTICAL_FLAG_WIDTH};
  background-color: ${(props) => props.theme.palette.backgrounds.white};
  box-shadow:
    0 2px 4px 0 ${(props) => transparentize(0.9, props.theme.palette.black)},
    0 3px 6px 0 ${(props) => transparentize(0.85, props.theme.palette.black)};
`;

export default VerticalFlag;
