import { css } from "styled-components";

import {
  InputStyles,
  InputStylesProps,
  StyledLabelText,
} from "../PlayfulStyles/InputStyles";
import { fontSizeByTextStyle } from "../Text";

// raw-loader solution - https://stackoverflow.com/a/58983746/5133172
// eslint-disable-next-line import/no-webpack-loader-syntax
import ReactPhoneInputHighResCss from "!!raw-loader!react-phone-input-2/lib/high-res.css";

const ReactPhoneInputStyles = css<InputStylesProps>`
  ${ReactPhoneInputHighResCss};

  .react-phone-container {
    font-family: inherit;
    font-size: ${(props) => fontSizeByTextStyle(props.theme).body}rem;

    .react-phone-input {
      ${InputStyles};

      height: auto;
      line-height: ${(props) => props.theme.typography.lineHeights.form};
      padding-left: ${(props) => props.theme.spacings.astronomic};

      &.open {
        background: ${(props) => props.theme.palette.white};
      }
    }

    .react-phone-country {
      border: ${(props) => props.theme.borders.width.medium}px solid transparent;
      border-radius: ${(props) => props.theme.borders.radius.medium}px;
      outline: none;
      background: transparent;

      .selected-flag {
        width: 5.25rem;
        padding: 0 ${(props) => props.theme.spacings.medium};
        background: transparent;
        border: ${(props) => props.theme.borders.width.medium}px solid
          transparent;
        border-radius: ${(props) => props.theme.borders.radius.medium}px;

        :hover,
        :focus {
          box-shadow: 0 0 0 3px
            ${(props) =>
              props.theme.palette[props.invalid ? "error" : "brand"][300]};
          border-color: ${(props) =>
            props.theme.palette[props.invalid ? "error" : "brand"][300]};
        }

        .flag {
          top: -2px;
          bottom: 0;
          margin: auto;
        }

        .arrow {
          top: 2px;
          box-sizing: border-box;
          position: relative;
          display: block;
          transform: scale(var(--ggs, 1));
          width: 22px;
          height: 22px;
          border: 2px solid transparent;
          border-radius: 100px;
          color: ${(props) => props.theme.palette.gray[700]};
        }
        .arrow::after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid;
          border-right: 2px solid;
          transform: rotate(45deg);
          left: 4px;
          top: 2px;
        }

        .arrow.up::after {
          transform: rotate(-135deg);
          top: 6px;
        }

        ${(props) =>
          props.disabled &&
          css`
            pointer-events: none;
            opacity: 0.5;
          `};
      }

      &.open {
        background: transparent;
        box-shadow:
          0 0 0 1px
            ${(props) =>
              props.theme.palette[props.invalid ? "error" : "brand"][300]},
          0 0 0 2px
            ${(props) =>
              props.theme.palette[props.invalid ? "error" : "brand"][300]}
            inset;
        border-color: ${(props) =>
          props.theme.palette[props.invalid ? "error" : "brand"][300]};
        border-radius: ${(props) => props.theme.borders.radius.medium}px;

        .selected-flag {
          background: transparent;
          border-radius: ${(props) => props.theme.borders.radius.medium}px;
        }
      }
    }

    .react-phone-dropdown {
      max-height: ${(props) =>
        props.scale === "medium" ? "24rem" : "21.5rem"};
      margin: ${(props) => props.theme.spacings.tiny} 0 0;
      border: none;
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15));

      .divider {
        padding-bottom: 0;
        margin-bottom: 0;
      }

      .country {
        display: flex;
        border-top: 1px solid ${(props) => props.theme.palette.gray[50]};
        padding: ${(props) =>
          props.scale === "medium"
            ? props.theme.spacings.big
            : props.theme.spacings.medium};

        &.highlight,
        :hover,
        :focus {
          background-color: ${(props) => props.theme.palette.brand[300]};
        }

        .flag {
          position: relative;
          top: -${(props) => props.theme.spacings.tiny};
          left: auto;
          margin-right: ${(props) => props.theme.spacings.small};
        }

        .country-name {
          color: ${(props) => props.theme.palette.gray[900]};
          line-height: ${(props) =>
            props.theme.typography.lineHeights.headline};
        }

        .dial-code {
          color: ${(props) => props.theme.palette.gray[500]};
          margin-left: auto;
        }
      }
    }

    /**
   * Medium scale label must show above the input on load since the dial code
   * is shown immediately.
   */
    + ${StyledLabelText} {
      animation: none;
      font-size: ${(props) => fontSizeByTextStyle(props.theme).body}rem;
      top: ${(props) => `calc(-100% - ${props.theme.spacings.big})`};
      left: 0;
    }
  }
`;

export default ReactPhoneInputStyles;
