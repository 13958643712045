import styled, { css } from "styled-components";

import Text, { fontSizeByTextStyle, TextProps } from "../Text/index";

const Dash = styled(Text)<TextProps>`
  display: inline-block;
  position: relative;
  padding-left: ${(props) => props.theme.spacings.small};
  padding-right: ${(props) => props.theme.spacings.small};
  z-index: 0;

  &:after {
    content: "";
    width: 100%;
    background-color: ${(props) => props.theme.palette.mint[300]};
    position: absolute;
    left: 0;
    bottom: -0.188rem;
    z-index: -1;
    transform: skew(-15deg, 0deg);
    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0 100%);
    ${(props) => css`
      height: ${fontSizeByTextStyle(props.theme)[props.textStyle || "body"] *
      0.875}rem;
    `}
  }
`;

export default Dash;
