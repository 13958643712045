import addYears from "date-fns/addYears";
import isAfter from "date-fns/isAfter";
import isPast from "date-fns/isPast";
import isSameDay from "date-fns/isSameDay";

import MonthsEnum from "./MonthsEnum";

type DateEntry = { date: Date; isEnabled: boolean } | null;
export type WeekDaysRow = [
  DateEntry,
  DateEntry,
  DateEntry,
  DateEntry,
  DateEntry,
  DateEntry,
  DateEntry,
];
export type MonthDaysMatrix = WeekDaysRow[];
type CreateMonthOptions = {
  disableAfterOneYearDates?: boolean;
  disablePastDates?: boolean;
  disableWeekendDates?: boolean;
  enabledDates?: Date[];
};

const isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6;
const getOneYearFromNow = () => addYears(new Date(), 1);

const shouldEnableDate = (date: Date, options?: CreateMonthOptions) =>
  !(
    (options?.disableWeekendDates && isWeekend(date)) ||
    (options?.disablePastDates && isPast(date)) ||
    (options?.disableAfterOneYearDates && isAfter(date, getOneYearFromNow())) ||
    (options?.enabledDates &&
      !options.enabledDates.some((enabledDate) => isSameDay(date, enabledDate)))
  );

const createMonthDaysMatrix = (
  month: MonthsEnum,
  year: number,
  options?: CreateMonthOptions,
): Readonly<MonthDaysMatrix> => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const numberOfDaysInCurrentMonth = endDate.getDate();

  const matrix: MonthDaysMatrix = [];

  let date = 1;

  for (let weekIndex = 0; weekIndex < 6; weekIndex += 1) {
    matrix.push([null, null, null, null, null, null, null]);

    for (let dayIndex = 0; dayIndex < 7; dayIndex += 1) {
      // By default Sunday has number 0 but we want to place it at the end of the days list.
      const startDateDay = startDate.getDay() === 0 ? 7 : startDate.getDay();

      if (
        date > numberOfDaysInCurrentMonth ||
        (weekIndex === 0 && dayIndex + 1 < startDateDay)
      ) {
        matrix[weekIndex][dayIndex] = null;
      } else {
        const dateObject = new Date(year, month, date);
        matrix[weekIndex][dayIndex] = {
          date: dateObject,
          isEnabled: shouldEnableDate(dateObject, options),
        };
        date += 1;
      }
    }
  }

  return matrix;
};

export default createMonthDaysMatrix;
