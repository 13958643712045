import React from "react";

import Divider from "../Divider";
import Spacings from "../Spacings";
import CalendarMonth from "./CalendarMonth";
import CalendarMonthWeeks from "./CalendarMonthWeeks";
import CalendarWeekDays from "./CalendarWeekDays";
import createMonthDaysMatrix from "./createMonthDaysMatrix";
import useCalendar from "./useCalendar";

type CalendarProps = {
  className?: string;
  baseId?: string;
  disableAfterOneYearDates?: boolean;
  disablePastDates?: boolean;
  selectedDate?: Date;
  disableWeekendDates?: boolean;
  enabledDates?: Date[];
  monthNames?: React.ComponentProps<typeof CalendarMonth>["monthNames"];
  weekDays?: React.ComponentProps<typeof CalendarWeekDays>["weekDays"];
  toPrevMonthLabel?: React.ComponentProps<
    typeof CalendarMonth
  >["toPrevMonthLabel"];
  toNextMonthLabel?: React.ComponentProps<
    typeof CalendarMonth
  >["toNextMonthLabel"];
  onDateSelect?: (date: Date) => void;
};

const Calendar: React.FC<CalendarProps> = ({
  className,
  baseId,
  selectedDate,
  monthNames,
  weekDays,
  disableAfterOneYearDates = false,
  disablePastDates = false,
  disableWeekendDates = false,
  enabledDates,
  toPrevMonthLabel,
  toNextMonthLabel,
  onDateSelect,
}) => {
  const calendar = useCalendar({ selectedDate });

  const getDateElementId = (date: Date) => {
    const dateId = date.toLocaleDateString();

    return baseId ? `${baseId}-${dateId}` : dateId;
  };

  const focusDateElement = (date: Date) => {
    // Waits until new calendar days are rendered.
    setTimeout(() => {
      const newFocusedElement = document.getElementById(getDateElementId(date));

      if (newFocusedElement && newFocusedElement.focus) {
        newFocusedElement.focus();
      }
    });
  };

  const handleDateClick = (date: Date) => {
    calendar.setCurrentDate(date);

    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  const handleDayKeyUp = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.code) {
      case "ArrowLeft":
        focusDateElement(calendar.toPrevDate());
        break;
      case "ArrowRight":
        focusDateElement(calendar.toNextDate());
        break;
      case "ArrowUp":
        focusDateElement(calendar.to7DaysEarlierDate());
        break;
      case "ArrowDown":
        focusDateElement(calendar.to7DaysLaterDate());
        break;
      default:
        break;
    }
  };

  const weeks = createMonthDaysMatrix(
    calendar.currentDate.getMonth(),
    calendar.currentDate.getFullYear(),
    {
      disableAfterOneYearDates,
      disablePastDates,
      disableWeekendDates,
      enabledDates,
    },
  );

  return (
    <Spacings.Stack scale="medium" data-testid="calendar" className={className}>
      <CalendarMonth
        monthNames={monthNames}
        currentDate={calendar.currentDate}
        toPrevMonthLabel={toPrevMonthLabel}
        toNextMonthLabel={toNextMonthLabel}
        onPrevMonthClick={calendar.toPrevMonthDate}
        onNextMonthClick={calendar.toNextMonthDate}
      />

      <Spacings.Stack scale="tiny">
        <CalendarWeekDays weekDays={weekDays} />
        <Divider />
        <CalendarMonthWeeks
          dayId={getDateElementId}
          selectedDate={selectedDate}
          currentDate={calendar.currentDate}
          weeks={weeks}
          onDayClick={handleDateClick}
          onDayKeyUp={handleDayKeyUp}
        />
      </Spacings.Stack>
    </Spacings.Stack>
  );
};

export default Calendar;
